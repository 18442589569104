.alert--fontStyle {
  color: white !important;
  font-weight: bold;
  font-family: Rubik-Regular;
  letter-spacing: -0.16px;
  font-size: 14px;
  font-weight: 500;
}

.alert {
  border-radius: 3px;
  padding: 16px 12px !important;
  &--danger {
    background-color: #e06f6f;
  }
  &--success{
    background-color: #7ae06f;
  }
}
