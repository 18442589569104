.CommonStyleOfPagination {
  background-color: #f3f4f6;
  border-radius: 4px;
  border: 2px solid transparent;
  font-family: Rubik-medium;
  min-width: 34px;
  min-height: 34px;
}

.currentPage {
  padding: 0 2px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
