.bodyViewDataUser {
  margin-left: 30px;
  margin-right: 30px;
  width: 25vw;
  padding: 20px;

  &__buttonChange {
    color: rgba(0, 189, 247, 1);
    font-family: 'Rubik-Medium';
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }

  &__linkOut {
   text-decoration: none;
  }

  &__buttonOut {
    color: rgba(144, 144, 144, 1);
    font-family: 'Rubik-Medium';
    font-size: 18px;
    font-weight: 500;
    text-decoration: underline;
  }

  &__greyText {
    color: rgba(71, 70, 73, 1);
    font-family: 'Rubik-Medium';
    font-size: 14px;
    font-weight: 400;
  }
}
