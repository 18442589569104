.bodyEditPassword {
  margin-left: 30px;
  margin-right: 30px;
  width: 25vw;
  padding: 20px;
}

input {
  border: none;
  outline: none;
  :hover,
  :focus,
  :active {
    border: none;
    outline: none;
  }
  background-color: #eceeefff;
}

.inputStyle {
  font-size: 14px;
  letter-spacing: -0.18px;
  width: 100%;
  height: 100%;
  outline: 0;

  &--invalid {
    border-color: #c85252;
  }

  &::placeholder {
    color: #768187;
  }
  &:focus {
    border-color: #00bdf7;
  }

  &:input:-internal-autofill-selected {
    background-color: rgba(69, 88, 98, 0.1) !important;
  }
}

.inputContainer {
  flex-wrap: nowrap !important;
  height: 45px;
}

.resetPasswordErrorMsg {
  color: red;
  margin-top: 1em;
}

.bodyEditSelfUser__dividerLines {
  border-bottom: none;
}