.text-area-container {
  height: 100%;
}

.iconInputLogin {
  width: 40px;
  height: 100%;
}

.styleCommonInputAndIcon {
  font-family: "Rubik-Regular";
  box-sizing: border-box;
  border-radius: 3px;
  background-color: rgba(69, 88, 98, 0.1);
  padding: 10px 12px !important;
}

.inputStyle {
  border: 2px solid transparent;
  font-size: 14px;
  letter-spacing: -0.18px; /*refatorar*/
  width: 100%;
  height: 100%;
  outline: 0;

  &--invalid {
    border-color: #c85252;
  }

  &::placeholder {
    color: #768187;
  }
  &:focus {
    border-color: #00bdf7;
  }

  &:input:-internal-autofill-selected {
    background-color: rgba(69, 88, 98, 0.1) !important;
  }
}
