$widgetColor: #f3f4f6;
.widget {
  border-radius: 7px;
  background-color: $widgetColor;
  height: 40px;
  width: 100%;
  padding: 8px;
  cursor: pointer;
  border: 2px solid transparent;

  &:hover {
    border-color: #3FCDF9;
  }
  &__nameUser {
    margin: 0 0 0 8px;
  }
}
