.containerSpin {
  position: absolute;
  z-index: 1;
}

.spin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(10, 23, 55, .3);
}
