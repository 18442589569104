.button {
  width: 100%;
  border: 0 none;
  border-radius: 50px;
  padding: 11px 0;
  color: #fff;
  font-weight: bold;
  font-family: Rubik-Medium;

  &:hover {
    background-color: #00b0e5;
  }

  &--Await {
    background-color: #abe6fc;
    &:hover {
      background-color: #abe6fc;
    }
  }

  &--noAwait {
    background-color: #00bdf7;
  }

  &--AwaitWhite {
    background-color: #fff;
    color: #00bdf7;
    border: 1px solid #00bdf7;

    &:hover {
      background-color: #fff;
    }
  }

  &--noAwaitWhite {
    background-color: #fff;
    color: #00bdf7;
    border: 1px solid #00bdf7;

    &:hover {
      background-color: #e5f6fc;
    }
  }

  &--grey {
    background-color: #949494;
  }

  &--primary {
    background-color: #00bdf7;
    border-radius: 4px;
    padding: 5px;
  }

  &--secondary {
    color: #00bdf7;
    border: 1px #00bdf7 solid;
    background-color: #fff;
    border-radius: 4px;
    padding: 4px;
    &:hover {
      background-color: #e5f6fc;
    }
  }

  &:focus {
    outline: 1px dashed #000;
  }
}
