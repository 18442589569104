.customTable {
  overflow: hidden;
  font-family: Rubik-Regular;
  font-weight: 500;
  &.card {
    border: 0;
    border-radius: 8px;
  }
  &__head {
    font-size: 19px;
    background-color: #00bdf7;
    font-family: Rubik-Medium;
    color: white;
  }
  tbody {
    color: #949494;
    & tr {
    }
  }

  & .openCollapse {
    transform: rotate(-90deg);
  }
  & .closeCollapse {
    transform: rotate(90deg);
  }
  & .__btn {
    cursor: pointer;
    background: none;
    border: 0;
    color: #949494;
  }
}