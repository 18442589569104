.iconInputLogin {
  width: 40px;
  height: 100%;
}

.styleCommonInputAndIcon {
  font-family: 'Rubik-Regular';
  box-sizing: border-box;
  border-radius: 0px !important;
  background-color: rgba(69, 88, 98, 0.1);
  padding: 10px 12px !important;
}

.inputStyle {
  border: 2px solid transparent;
  font-size: 14px;
  letter-spacing: -0.18px;
  width: 100%;
  height: 100%;
  outline: 0;

  &--invalid {
    border-color: #c85252;
  }

  &::placeholder {
    color: #768187;
  }
  &:focus {
    border-color: #00bdf7;
  }

  &:input:-internal-autofill-selected {
    background-color: rgba(69, 88, 98, 0.1) !important;
  }
}

.inputContainer {
  flex-wrap: nowrap !important;
}

.passwordInput {
  width: 86%;
  height: auto;
}
.checkboxInput {
  height: 20px !important;
  width: auto !important;
  margin-right: 5px;
}
.checkboxLabel {
  height: 20px !important;
  font-size: 12px;
  width: 86%;
}

.passwordActionButton {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  color: #495057;
  cursor: pointer;
  background-color: rgba(69, 88, 98, 0.1);
}

// disable 'view password' function of Microsoft Edge to prevent duplicated buttons
::-ms-reveal {
  display: none;
}
