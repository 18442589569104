.main {
  width: 20%;
  display: flex;
  flex-flow: column;
}

.buttonsContainer {
  margin-top: 1em;
  display: flex;
  gap: 1em;
  justify-content: space-between;
}