.forgotPasswordText {
  font-size: 14px;
  letter-spacing: -0.18px;
  color: #4A4A4A;
  text-decoration: underline;
  &:hover {
    color: #000;
  }
}

.rememberLogin {
  display: flex; 
  justify-content: center; 
  align-items: baseline;
}