.buttonOFPagination {
  background-color: #f3f4f6;
  border-radius: 4px;
  border: 2px solid transparent;
  letter-spacing: -3px;
  font-family: Rubik-medium;
  width: 34px;
  height: 34px;

  &:disabled {
    opacity: 0.5;
  }
}
