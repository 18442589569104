.bodyEditSelfUser {
  width: 100%;
  padding-left: 1em;
  display: flex;
  flex-direction: row;

  &__leftWrapper {
    padding-top: 10px;
    width: 10vw;
  }
  &__rightWrapper {
    padding-top: 10px;
  }
  &__name {
    font-size: 18px;
    line-height: 21.33px;
    font-family: 'Rubik-Medium';
    margin-left: 30px;
    margin-right: 30px;
    width: 25vw;
    padding-left: 20px;
  }
  &__greyText,
  a {
    color: #949494;
    cursor: pointer;
  }
  &__fontSize {
    font-size: 14px;
  }
  &__dividerLines {
    padding: 22px 0 4px 0;
    border-bottom: solid 1px #f3f4f6;
    &--inFocus {
      border-bottom: solid 1px skyblue;
    }
  }
  a {
    &:hover {
      color: #000;
    }
  }
  .iconLogout {
    position: relative;
    top: -2px;
    left: -5px;
  }
  .inputOcult {
    border: 0 solid transparent;
    &:focus {
      outline: none;
    }
  }
  &__buttonChangePhoto {
    color: rgba(0, 189, 247, 1);
    font-family: 'Rubik-Medium';
    font-size: 18px;
    text-decoration: underline;
  }
}

.togglePasswordVisibilityIcon {
  margin: auto;
  cursor: pointer;
}

.submitErrorMsg {
  color: red;
  margin-top: 1em;
  max-width: 20em;
}
