$searchBarHeight: 104px;
.searchForm {
  gap: 0.1em;
}
.searchBar {
  height: $searchBarHeight;
  margin: 0 !important;
  padding: calc(104px * 0.3) 30px;
  font-family: Rubik-Regular;
  width: 100%;
  border-bottom: 2px solid #d6d6d6;

  &__inputSearch {
    background-color: white;
    padding: 0.5rem 0.8rem;
    border: 1px white;
    border-radius: 0 0.25rem 0.25rem 0;
    width: 20rem;
    min-height: 46px;

    & input {
      border: 0 transparent;
      background-color: transparent;
      width: 100%;
      height: 100%;
      font-size: 1.1rem;

      &:focus {
        outline: none;
      }
    }
  }

  &__buttonSearch {
    padding: 0.5rem 1rem;
    margin-right: 25px;
    border-radius: 0 0.25rem 0.25rem 0;
    background-color: #00bdf7;
    border: 0 transparent;
    img {
      vertical-align: middle;
    }
  }

  &__selectType {
    background-color: white;
    padding: 0.5rem 0.8rem;
    border: 1px transparent;
    border-radius: 3px;
    width: 10rem;
    min-height: 46px;
  }
}
