.contentClass {
  font-family: Rubik-Regular;
  &__title {
    font-weight: bold;
  }
  &__subject {
    padding: 8px;
    border-radius: 4px;
    font-weight: bolder;
    color: whitesmoke;
    background-color: red;
  }
  &__contents {
  }
  .iconButton {
    position: relative;
    top: -3px;
    left: -10px;
  }
}
