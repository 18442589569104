.containerClassItem {
  padding: 0 0 0 16px;
  display: flex;

  .relationContents__title {
    color: #00bdf7;
    font-weight: bolder;
  }

  .contentClassItem {
    border-left: 4px solid #00bdf7;
    padding: 10px 40px 0 40px;
    max-width: 100%;

    .markup {
      background-color: #00bdf7;
      border-radius: 30px;
      position: relative;
      float: left;
      left: -57px;
      height: 30px;
      width: 30px;
      z-index: 10000;
    }

    &--lastItem {
      margin: 0 0 40px 0;
    }

    .meSalvaIcon {
      margin: 20px 0 0 0;
    }

    &__description {
      font-weight: bold;
      &--withMarkup {
        position: relative;
        left: -28px;
      }
    }
  }
}
