@font-face {
  font-family: Rubik-Medium;
  src: local(Rubik-Medium), url(./assets/fonts/Rubik-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Rubik-Regular;
  src: local(Rubik-Regular), url(./assets/fonts/Rubik-Regular.ttf) format("truetype");
}

@font-face {
  font-family: Rubik-Light;
  src: local(Rubik-Regular), url(./assets/fonts/Rubik-Light.ttf) format("truetype");
}

.titlePages {
  font-family: "Rubik-Medium";
}
