@keyframes openNotify {
  0% {
    left: 100%;
  }
  10% {
    left: 90%;
  }
  20% {
    left: 80%;
  }
  30% {
    left: 70%;
  }
  40% {
    left: 60%;
  }
  50% {
    left: 50%;
  }
  60% {
    left: 40%;
  }
  70% {
    left: 30%;
  }
  80% {
    left: 20%;
  }
  90% {
    left: 10%;
  }
  100% {
    left: 0%;
  }
}

.notify {
  animation: openNotify 0.9s ease-out 0s normal;
  color: white;
  cursor: pointer;
  font-family: Rubik-Medium, Arial, Helvetica, sans-serif;
  padding: 2px 2px 2px 34px;
  position: absolute;
  overflow: hidden;
  z-index: 200;
  &--error {
    background-color: #C85252;
  }
  &--success {
    background-color: #50AD46;
  }
  &__closeBtn {
    position: absolute;
    right: 6px;
    &:hover {
      opacity: 0.6;
    }
  }
}
