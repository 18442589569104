$borderRadiusListItem: 4px;
$BGColorModifiersListItem: #f3f3f3;

.sideBar {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: calc(100vh - 104px);

  &__TopItem {
    margin: 2em 0 3em 0;
    width: 100%;
  }

  &__list {
    list-style: none;
    font: 500 18px Rubik-Medium, sans-serif;
    letter-spacing: -0.23px;
    padding: 0 10px;
    height: 100%;
    overflow-y: auto;

    &__item {
      margin: 20px 0;
      padding: 10px;

      & a {
        color: #474649;
        cursor: pointer;
      }

      &--selected {
        border-radius: $borderRadiusListItem;
        background-color: $BGColorModifiersListItem;
      }

      &--disabled {
        opacity: 0.3;
      }

      &:hover {
        border-radius: $borderRadiusListItem;
        background-color: $BGColorModifiersListItem;
      }
      a {
        &:hover {
          text-decoration: none;
          color: #000;
        }
      }
    }
  }
}
