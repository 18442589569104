.selectStyle {
  font-family: "Rubik-Regular";
  font-size: 14px;
  letter-spacing: -0.18px;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: white;
  width: 100%;
  min-width: 5rem;
  border: 2px solid transparent;
  text-align: center;
  &:focus {
    border-color: #00bdf7;
  }
}
