.avatarContainer {
  width: fit-content;
}

.avatarRemove {
  width: fit-content;
  border-radius: 50px;
  position: absolute;
  background-color: rgba(151, 147, 147, 0.8);
  color: #fff;
  font-size: 20px;
  width: 104px;
  height: 104px;
  text-align: center;
  padding-top: 39px;
}
