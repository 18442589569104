.underline {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #00bdf7;
}
.fontColor {
  &--grey {
    color: #aab1b5;
  }
  &--blue {
    color: #00bdf7;
  }
}
