$topBarHeight: 104px;
.topBar {
  height: $topBarHeight;
  margin: 0 !important;
  padding: calc(104px * 0.3) 30px;
  font-family: Rubik-Regular;
  width: 100%;
  border-bottom: 2px solid #d6d6d6;

  &__logo {
    margin: 0 150px 0 0;
  }

  &__inputSearch {
    background-color: hsl(220, 14%, 96%);
    padding: 0.6rem 0.8rem;
    border: 1px transparent;
    width: 25rem;

    & input {
      border: 0 transparent;
      background-color: transparent;
      width: 100%;
      height: 100%;
      font-size: 1.1rem;

      &:focus {
        outline: none;
      }
    }
  }

  &__buttonSearch {
    padding: 0.5rem 1rem;
    margin-right: 25px;
    border-radius: 0 0.25rem 0.25rem 0;
    background-color: #00bdf7;
    border: 0 transparent;
    img {
      vertical-align: middle;
    }
  }

  .iconTopBar {
    position: relative;
    top: -2px;
  }

  &__rightSide {
    display: flex;
    flex: 1;
    flex-direction: column;

    &__contentUser {
      align-self: flex-end;
    }
  }

  &__selectType {
    background-color: hsl(220, 14%, 96%);
    padding: 0.6rem 0.8rem;
    border: 1px transparent;
    width: 10rem;
    height: 100%;
  }
}
