.containerScreen {
  height: 100vh;
  background: url('../../assets/images/background_login.svg') no-repeat 0 ;
  background-size: cover;
}
  .containerScreen__rounded {
    min-height: 407px;
    border-radius: 12px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.12);
    background-color: #f6f9ff;
    overflow: hidden;
  }
