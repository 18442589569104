.commonFontStyle {
  color: #455862;
  &--title {
    font-family: Rubik-Medium;
  }
  &--content {
    font-family: Rubik-Regular;
    font-size: 14px;
    letter-spacing: -0.18px;
  }
}
