.itemsPerPageSelectTitle {
  padding-right: 0;
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  select {
    max-width: 70px;
    height: 46px;
  }
  span {
    margin-right: 12px;
  }
}
