.viewModal {
  height: calc(100vh - 104px);
  position: absolute;
  z-index: 1;
  display: flex;
  width: 100%;
  flex-direction: column;

  &--centralizeVertical{
    justify-content: center;
  }
}

.background {
  background-color: rgba(10, 23, 55, 0.3);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.containerModal {
  position: relative;
  z-index: 3;
  padding: 8px;
  &--fit {
    min-width: 35%;
    width: fit-content;
  }
  &--xl {
    width: 100%;
  }
  &--lg {
    width: 75%;
  }
  &--md {
    width: 50%;
    height: 70%;
  }
  &--sm {
    width: 35%;
  }
  &--horizontalRight {
    align-self: flex-end;
  }
  &--horizontalCenter {
    align-self: center;
  }
  &__content {
    padding: 42px 24px 24px;

    &.card {
      border-radius: 8px;
      min-height: 65%;
    }
    &__header {
      &__closeButton {
        position: absolute;
        right: 20px;
        top: 8px;
        width: 16px;
        height: 16px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    &__body {
      max-height: calc(100vh - 189px);
      overflow-y: auto ;
      overflow-x: hidden ;
      padding: 8px 0 0 0;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}
