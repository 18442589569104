.cardContainer {
  display: flex;
  min-height: 142px;
  flex-direction: row;
  border-radius: 4px;
  background-color: #fff;
  border: 3px solid #e2e5ee;
  border-left: 5px solid #00bdf7;
  & .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 22px;
    margin-left: 16px;
    margin-bottom: 22px;
    &__title {
      font-family: 'Rubik-Regular';
      font-size: 24px;
      line-height: 20px;
    }
    &__value {
      font-family: 'Rubik-Medium';
      font-size: 44px;
      line-height: 20px;
    }
  }
}
