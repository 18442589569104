.cleanMarginAndPading {
  padding: 0 !important;
  margin: 0 !important;
}

.contentView {
  background-color: #f3f4f6;
  height: calc(100vh - 104px);
  padding: 30px;
  overflow: auto;
}
